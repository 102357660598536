import Vue from 'vue';
import Vuex from 'vuex';
import scroll from './modules/scroll';
import language from './modules/language';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    endpoints: {}
  },
  modules: {
    scroll,
    language
  },
  mutations: {
  },
  actions: {
  }
});
