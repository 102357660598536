import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function getDefaultLang () {
  const languages = Object.getOwnPropertyNames(loadLocaleMessages());
  const navigatorLang = getNavigatorLang(languages);
  const urlLang = getUrlLang(languages);
  return urlLang || navigatorLang || 'de';
}

function getNavigatorLang (languages) {
  let matched = null;
  languages.forEach(lang => {
    if (!matched) {
      matched = matchLang(lang, navigator.language);
    }
  });
  if (!matched) {
    languages.forEach(lang => {
      const languagePartials = navigator.language.split('-')[0];
      if (!matched) {
        matched = matchLang(lang, languagePartials);
      }
    });
  }
  return matched;
}

function matchLang (lang, browserLang) {
  let matched = null;
  if (lang === browserLang) {
    matched = lang;
  }
  if (browserLang === 'en-US' && lang === 'us') {
    matched = lang;
  }
  if ((browserLang === 'en-UK' || browserLang === 'en-GB' || browserLang === 'en-GB-oxendict') && lang === 'uk') {
    matched = lang;
  }
  if (browserLang === 'pt-BR' && lang === 'br') {
    matched = lang;
  }
  return matched;
}

function getQueryParams (queryParam) {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });
  return params[queryParam];
}

function getUrlLang (languages) {
  let matched = null;
  const localeFromUrl = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');
  if (localeFromUrl && localeFromUrl !== '/') {
    languages.forEach(lang => {
      if (!matched) {
        matched = matchLang(lang, localeFromUrl);
      }
    });
  }
  const searchParam = getQueryParams('lang');
  if (searchParam) {
    matched = null;
    languages.forEach(lang => {
      if (!matched) {
        matched = matchLang(lang, searchParam);
      }
    });
  }
  return matched;
}

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getDateTimeFormats () {
  return {
    en: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
      },
      month: {
        month: 'short'
      },
      monthLong: {
        month: 'long'
      },
      dayAndDate: {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric'
      }
    },
    de: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
      },
      month: {
        month: 'short'
      },
      monthLong: {
        month: 'long'
      },
      dayAndDate: {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric'
      }
    },
    ru: {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric'
      },
      month: {
        month: 'short'
      },
      monthLong: {
        month: 'long'
      },
      dayAndDate: {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric'
      }
    }
  };
}

export default new VueI18n({
  locale: getDefaultLang() || process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages: loadLocaleMessages(),
  dateTimeFormats: getDateTimeFormats()
});
