export default {
  namespaced: true,
  state: {
    scrollTop: -1,
    winHeight: 0,
    winWidth: 0,
    navWidth: 0,
    logoHeight: 0,
    logoWidth: 0,
    header: true,
    footer: true,
    brand: '',
    menu: [],
    hotspotFocus: '',
    hotspotOpen: '',
    whitePage: false,
    menuHidden: false,
    loginCode: '',
    loginStatus: -1,
    authLevel: -1
  },
  getters: {
    scrollTop: (state) => state.scrollTop,
    winHeight: (state) => state.winHeight,
    winWidth: (state) => state.winWidth,
    navWidth: (state) => state.navWidth,
    logoHeight: (state) => state.logoHeight,
    logoWidth: (state) => state.logoWidth,
    resize: (state) => [state.winWidth, state.winHeight],
    header: (state) => state.header,
    footer: (state) => state.footer,
    brand: (state) => state.brand,
    menu: (state) => state.menu,
    hotspotFocus: (state) => state.hotspotFocus,
    hotspotOpen: (state) => state.hotspotOpen,
    whitePage: (state) => state.whitePage,
    menuHidden: (state) => state.menuHidden,
    loginCode: (state) => state.loginCode,
    loginStatus: (state) => state.loginStatus,
    authLevel: (state) => state.authLevel
  },
  mutations: {
    scrollTop: (state, value) => {
      state.scrollTop = value;
    },
    resize: (state) => {
      const w = window.innerWidth;
      const h = window.innerHeight;
      if (state.winHeight !== h || state.winWidth !== w) {
        state.winWidth = w;
        state.winHeight = h;
      }
      const nav = document.querySelector('nav');
      if (nav) {
        state.navWidth = nav.clientWidth;
      }
      const logo = document.querySelector('.logo');
      if (logo) {
        state.logoHeight = logo.clientHeight;
        state.logoWidth = logo.clientWidth;
      }
    },
    setHeader: (state, value) => {
      state.header = value;
    },
    setFooter: (state, value) => {
      state.header = value;
    },
    setBrand: (state, value) => {
      state.brand = value;
    },
    setMenu: (state, value) => {
      state.menu = value;
    },
    setHotspotFocus: (state, value) => {
      state.hotspotFocus = value;
    },
    setHotspotOpen: (state, value) => {
      state.hotspotOpen = value;
    },
    setWhitePage: (state, value) => {
      state.whitePage = value;
    },
    setMenuHidden: (state, value) => {
      state.menuHidden = value;
    },
    setLoginCode: (state, value) => {
      state.loginCode = value;
    },
    setLoginStatus: (state, value) => {
      state.loginStatus = value;
    },
    logout: (state, value) => {
      value.remove('lt');
      value.remove('code');
      value.remove('token');
      state.loginStatus = -1;
      state.loginCode = '';
      state.authLevel = -1;
      document.location.reload();
    },
    setAuthLevel: (state, value) => {
      state.authLevel = value;
    }
  },
  actions: {}
};
